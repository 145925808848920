// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@popperjs/core")

// import "bootstrap"
// import { Tooltip, Popover } from "bootstrap"

// require("../stylesheets/application.scss")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"



// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

// App libraries
require("bootstrap")
require("vendors/jquery.mask.min")

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// // If you're using Turbolinks. Otherwise simply use: jQuery(function () {
// document.addEventListener("turbolinks:load", () => {
//     // Both of these are from the Bootstrap 5 docs
//     var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//     var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//         return new Tooltip(tooltipTriggerEl)
//     })

//     var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//     var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//         return new Popover(popoverTriggerEl)
//     })
// })

